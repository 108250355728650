<template>
  <div class="withdrawal-status" :class="platform" data-t="withdrawal-status">
    <div class="content">
      <div class="status-icon">
        <img
          v-if="status === 'success'"
          src="./assets/done.png"
          class="status-icon-image"
        />
        <img v-else src="./assets/cancel.png" class="status-icon-image" />
      </div>
      <div class="info">
        <div class="title">
          {{
            status === 'success'
              ? t('payments.withdrawal.invoiceCreated')
              : t('payments.withdrawal.invoiceFailed')
          }}
        </div>
        <div class="amount">
          {{ finalAmount }}
          <StIcon
            class="currency-icon"
            :name="currencyIcon"
            :size="isMobile ? 28 : 36"
          />
        </div>
        <div class="text">
          {{
            status === 'success'
              ? t('payments.withdrawal.includeFee', {
                  amount: commission,
                  currency,
                })
              : t('payments.withdrawal.tryAgainLater')
          }}
        </div>
      </div>
    </div>
    <div class="bottom-section">
      <div class="border"></div>
      <StButton
        :label="
          status === 'success'
            ? t('payments.withdrawal.goToTransactions')
            : t('payments.withdrawal.tryAgain')
        "
        :size="isMobile ? 'l' : 'xl'"
        type="gray"
        class="button"
        @click="handleClick"
      />
      <div v-if="status === 'success'" class="hint">
        {{ t('payments.withdrawal.withdrawalTime') }}
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { IconName } from '@st/ui/components/StIcon/types'
import { useWithdrawalStore } from '../../stores/useWithdrawalStore'

const { t } = useI18n()

const props = withDefaults(
  defineProps<{
    amount: string
    status: 'success' | 'error'
    currency: string
    platform: 'desktop' | 'mobile'
    commission?: string
  }>(),
  {
    platform: 'desktop',
  },
)

const { isMobile } = usePlatform()
const { format } = useCryptoFormatter()

const currencyIcon = computed(
  () => `c-crypto-icon-${props.currency?.toLowerCase()}` as IconName,
)

const amountColor = computed(() =>
  props.status === 'success'
    ? 'var(--palette-light-1000)'
    : 'var(--system-rates-down)',
)

const finalAmount = computed(() => {
  const formatedAmount = format(props.amount)
  if (props.status === 'success') {
    return `-${formatedAmount}`
  }

  return formatedAmount
})

const dropShadow = computed(() =>
  props.status === 'success'
    ? 'drop-shadow(0px 24px 40px rgba(245, 164, 23, 0.64)) drop-shadow(0px 18px 36px rgba(245, 116, 22, 0.48))'
    : 'drop-shadow(0px 16px 32px rgba(245, 22, 22, 0.48)) drop-shadow(0px 24px 120px rgba(245, 76, 23, 0.64))',
)

const withdrawalStore = useWithdrawalStore()
const { clearWithdrawalData } = withdrawalStore

const router = useRouter()

function handleClick() {
  if (props.status === 'success') {
    clearWithdrawalData()
    router.push('/personal/transactions')
  }

  if (props.status === 'error') {
    router.replace({
      query: {
        modal: 'payments',
        tab: 'withdrawal',
      },
    })
  }
}
</script>

<style scoped>
.withdrawal-status {
  display: flex;
  flex-direction: column;
  align-items: center;

  min-width: 480px;
  height: 520px;

  text-align: center;

  border-radius: var(--border-radius-150);
}

.content {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-300);
  align-items: center;

  width: 100%;
  height: 100%;
  padding: var(--spacing-600);

  text-align: center;
}

.info {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-125);
}

.title {
  z-index: 1;
  font: var(--desktop-text-lg-semibold);
}

.amount {
  font: var(--desktop-text-4xl-semibold);
  color: v-bind(amountColor);
}

.text {
  max-width: 384px;
  font: var(--desktop-text-sm-medium);
  color: var(--palette-light-600);
}

.bottom-section {
  position: relative;

  display: flex;
  flex-direction: column;
  gap: var(--spacing-150);
  justify-content: center;

  width: 100%;
  height: 100%;
  padding: var(--spacing-500);

  .border {
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 5px;

    background-image: url('./assets/border.svg');
    background-repeat: no-repeat;
    background-position: center;
  }
}

.button {
  width: 100%;
}

.status-icon-image {
  width: 120px;
  height: 120px;
  background-color: transparent;
  filter: v-bind(dropShadow);
}

.hint {
  font: var(--desktop-text-sm-medium);
  color: var(--text-tertiary);
}

.withdrawal-status.mobile {
  min-width: auto;
  height: auto;

  .title {
    font: var(--mobile-headline-semibold);
  }

  .content {
    gap: var(--spacing-100);
    padding: var(--spacing-250) var(--spacing-200);
  }

  .amount {
    display: flex;
    gap: var(--spacing-100);
    align-items: center;
    justify-content: center;

    font: var(--desktop-text-3xl-semibold);
  }

  .text {
    font: var(--mobile-text-content-regular);
  }

  .info {
    gap: var(--spacing-100);
  }

  .status-icon-image {
    width: 84px;
    height: 84px;
  }

  .border {
    background-size: 90% 100%;
  }

  .bottom-section {
    padding: var(--spacing-300) var(--spacing-200);
  }
}
</style>
